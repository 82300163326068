import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"
import formBackgroundImg from "../../assets/img/wheels/apartemen-desktop-bgl.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formSpinWheelApartemen {
    formSpinWheelApartemen {
      phoneNumber
      project
      unit
    }
  }
`

const ApartemenPage = (location) =>{
  
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  let productSelected = useRef()

  let listOfProduct = [
    { label: 'Aerium, Jakarta' , value: 'Aerium, Jakarta'},
    { label: 'Azure, Klaska Residence, Surabaya' , value: 'Azure, Klaska Residence, Surabaya'},
    { label: 'Element, Jakarta' , value: 'Element, Jakarta'},
    { label: 'Southgate Altuerra, Jakarta' , value: 'Southgate Altuerra, Jakarta'},
    { label: 'Southgate Elegance, Jakarta' , value: 'Southgate Elegance, Jakarta'},
    { label: 'Tower Kaina, Batam' , value: 'Tower Kaina, Batam'},
    { label: 'Tower Kalani, Batam' , value: 'Tower Kalani, Batam'},
  ]

  let aeriumUnitList = [
    {label: 'Aerium South Twr LT 10 No.3', value: 'Aerium South Twr LT 10 No.3'},
    {label: 'Aerium South Twr LT 10 No.5', value: 'Aerium South Twr LT 10 No.5'},
    {label: 'Aerium South Twr LT 11 No.3', value: 'Aerium South Twr LT 11 No.3'},
    {label: 'Aerium South Twr LT 12 No.10', value: 'Aerium South Twr LT 12 No.10'},
    {label: 'Aerium South Twr LT 15 No.11', value: 'Aerium South Twr LT 15 No.11'},
    {label: 'Aerium South Twr LT 15 No.12', value: 'Aerium South Twr LT 15 No.12'},
    {label: 'Aerium South Twr LT 15 No.15', value: 'Aerium South Twr LT 15 No.15'},
    {label: 'Aerium South Twr LT 15 No.3', value: 'Aerium South Twr LT 15 No.3'},
    {label: 'Aerium South Twr LT 15 No.5', value: 'Aerium South Twr LT 15 No.5'},
    {label: 'Aerium South Twr LT 16 No.11', value: 'Aerium South Twr LT 16 No.11'},
    {label: 'Aerium South Twr LT 16 No.15', value: 'Aerium South Twr LT 16 No.15'},
    {label: 'Aerium South Twr LT 16 No.5', value: 'Aerium South Twr LT 16 No.5'},
    {label: 'Aerium South Twr LT 17 No.10', value: 'Aerium South Twr LT 17 No.10'},
    {label: 'Aerium South Twr LT 17 No.15', value: 'Aerium South Twr LT 17 No.15'},
    {label: 'Aerium South Twr LT 17 No.3', value: 'Aerium South Twr LT 17 No.3'},
    {label: 'Aerium South Twr LT 17 No.5', value: 'Aerium South Twr LT 17 No.5'},
    {label: 'Aerium South Twr LT 18 No.10', value: 'Aerium South Twr LT 18 No.10'},
    {label: 'Aerium South Twr LT 18 No.15', value: 'Aerium South Twr LT 18 No.15'},
    {label: 'Aerium South Twr LT 18 No.3', value: 'Aerium South Twr LT 18 No.3'},
    {label: 'Aerium South Twr LT 18 No.5', value: 'Aerium South Twr LT 18 No.5'},
    {label: 'Aerium South Twr LT 19 No.15', value: 'Aerium South Twr LT 19 No.15'},
    {label: 'Aerium South Twr LT 19 No.3', value: 'Aerium South Twr LT 19 No.3'},
    {label: 'Aerium South Twr LT 19 No.5', value: 'Aerium South Twr LT 19 No.5'},
    {label: 'Aerium South Twr LT 2 No.3', value: 'Aerium South Twr LT 2 No.3'},
    {label: 'Aerium South Twr LT 2 No.5', value: 'Aerium South Twr LT 2 No.5'},
    {label: 'Aerium South Twr LT 2 No.6', value: 'Aerium South Twr LT 2 No.6'},
    {label: 'Aerium South Twr LT 20 No.15', value: 'Aerium South Twr LT 20 No.15'},
    {label: 'Aerium South Twr LT 20 No.3', value: 'Aerium South Twr LT 20 No.3'},
    {label: 'Aerium South Twr LT 20 No.5', value: 'Aerium South Twr LT 20 No.5'},
    {label: 'Aerium South Twr LT 21 No.3', value: 'Aerium South Twr LT 21 No.3'},
    {label: 'Aerium South Twr LT 22 No.10', value: 'Aerium South Twr LT 22 No.10'},
    {label: 'Aerium South Twr LT 22 No.15', value: 'Aerium South Twr LT 22 No.15'},
    {label: 'Aerium South Twr LT 22 No.3', value: 'Aerium South Twr LT 22 No.3'},
    {label: 'Aerium South Twr LT 23 No.10', value: 'Aerium South Twr LT 23 No.10'},
    {label: 'Aerium South Twr LT 23 No.11', value: 'Aerium South Twr LT 23 No.11'},
    {label: 'Aerium South Twr LT 23 No.15', value: 'Aerium South Twr LT 23 No.15'},
    {label: 'Aerium South Twr LT 23 No.5', value: 'Aerium South Twr LT 23 No.5'},
    {label: 'Aerium South Twr LT 25 No.10', value: 'Aerium South Twr LT 25 No.10'},
    {label: 'Aerium South Twr LT 25 No.11', value: 'Aerium South Twr LT 25 No.11'},
    {label: 'Aerium South Twr LT 25 No.15', value: 'Aerium South Twr LT 25 No.15'},
    {label: 'Aerium South Twr LT 26 No.10', value: 'Aerium South Twr LT 26 No.10'},
    {label: 'Aerium South Twr LT 26 No.15', value: 'Aerium South Twr LT 26 No.15'},
    {label: 'Aerium South Twr LT 27 No.10', value: 'Aerium South Twr LT 27 No.10'},
    {label: 'Aerium South Twr LT 27 No.11', value: 'Aerium South Twr LT 27 No.11'},
    {label: 'Aerium South Twr LT 27 No.15', value: 'Aerium South Twr LT 27 No.15'},
    {label: 'Aerium South Twr LT 27 No.3', value: 'Aerium South Twr LT 27 No.3'},
    {label: 'Aerium South Twr LT 27 No.5', value: 'Aerium South Twr LT 27 No.5'},
    {label: 'Aerium South Twr LT 28 No.10', value: 'Aerium South Twr LT 28 No.10'},
    {label: 'Aerium South Twr LT 28 No.11', value: 'Aerium South Twr LT 28 No.11'},
    {label: 'Aerium South Twr LT 28 No.15', value: 'Aerium South Twr LT 28 No.15'},
    {label: 'Aerium South Twr LT 28 No.3', value: 'Aerium South Twr LT 28 No.3'},
    {label: 'Aerium South Twr LT 28 No.5', value: 'Aerium South Twr LT 28 No.5'},
    {label: 'Aerium South Twr LT 28 No.6', value: 'Aerium South Twr LT 28 No.6'},
    {label: 'Aerium South Twr LT 29 No.10', value: 'Aerium South Twr LT 29 No.10'},
    {label: 'Aerium South Twr LT 29 No.11', value: 'Aerium South Twr LT 29 No.11'},
    {label: 'Aerium South Twr LT 29 No.15', value: 'Aerium South Twr LT 29 No.15'},
    {label: 'Aerium South Twr LT 29 No.3', value: 'Aerium South Twr LT 29 No.3'},
    {label: 'Aerium South Twr LT 29 No.5', value: 'Aerium South Twr LT 29 No.5'},
    {label: 'Aerium South Twr LT 3 No.10', value: 'Aerium South Twr LT 3 No.10'},
    {label: 'Aerium South Twr LT 3 No.11', value: 'Aerium South Twr LT 3 No.11'},
    {label: 'Aerium South Twr LT 3 No.3', value: 'Aerium South Twr LT 3 No.3'},
    {label: 'Aerium South Twr LT 3 No.5', value: 'Aerium South Twr LT 3 No.5'},
    {label: 'Aerium South Twr LT 30 No.10', value: 'Aerium South Twr LT 30 No.10'},
    {label: 'Aerium South Twr LT 30 No.11', value: 'Aerium South Twr LT 30 No.11'},
    {label: 'Aerium South Twr LT 30 No.3', value: 'Aerium South Twr LT 30 No.3'},
    {label: 'Aerium South Twr LT 30 No.5', value: 'Aerium South Twr LT 30 No.5'},
    {label: 'Aerium South Twr LT 31 No.10', value: 'Aerium South Twr LT 31 No.10'},
    {label: 'Aerium South Twr LT 31 No.11', value: 'Aerium South Twr LT 31 No.11'},
    {label: 'Aerium South Twr LT 31 No.3', value: 'Aerium South Twr LT 31 No.3'},
    {label: 'Aerium South Twr LT 31 No.5', value: 'Aerium South Twr LT 31 No.5'},
    {label: 'Aerium South Twr LT 32 No.10', value: 'Aerium South Twr LT 32 No.10'},
    {label: 'Aerium South Twr LT 32 No.11', value: 'Aerium South Twr LT 32 No.11'},
    {label: 'Aerium South Twr LT 32 No.3', value: 'Aerium South Twr LT 32 No.3'},
    {label: 'Aerium South Twr LT 32 No.5', value: 'Aerium South Twr LT 32 No.5'},
    {label: 'Aerium South Twr LT 33 No.10', value: 'Aerium South Twr LT 33 No.10'},
    {label: 'Aerium South Twr LT 33 No.11', value: 'Aerium South Twr LT 33 No.11'},
    {label: 'Aerium South Twr LT 33 No.3', value: 'Aerium South Twr LT 33 No.3'},
    {label: 'Aerium South Twr LT 33 No.5', value: 'Aerium South Twr LT 33 No.5'},
    {label: 'Aerium South Twr LT 35 No.10', value: 'Aerium South Twr LT 35 No.10'},
    {label: 'Aerium South Twr LT 35 No.11', value: 'Aerium South Twr LT 35 No.11'},
    {label: 'Aerium South Twr LT 35 No.3', value: 'Aerium South Twr LT 35 No.3'},
    {label: 'Aerium South Twr LT 35 No.5', value: 'Aerium South Twr LT 35 No.5'},
    {label: 'Aerium South Twr LT 36 No.10', value: 'Aerium South Twr LT 36 No.10'},
    {label: 'Aerium South Twr LT 37 No.10', value: 'Aerium South Twr LT 37 No.10'},
    {label: 'Aerium South Twr LT 37 No.3', value: 'Aerium South Twr LT 37 No.3'},
    {label: 'Aerium South Twr LT 37 No.5', value: 'Aerium South Twr LT 37 No.5'},
    {label: 'Aerium South Twr LT 5 No.10', value: 'Aerium South Twr LT 5 No.10'},
    {label: 'Aerium South Twr LT 5 No.11', value: 'Aerium South Twr LT 5 No.11'},
    {label: 'Aerium South Twr LT 5 No.3', value: 'Aerium South Twr LT 5 No.3'},
    {label: 'Aerium South Twr LT 6 No.1', value: 'Aerium South Twr LT 6 No.1'},
    {label: 'Aerium South Twr LT 6 No.10', value: 'Aerium South Twr LT 6 No.10'},
    {label: 'Aerium South Twr LT 6 No.3', value: 'Aerium South Twr LT 6 No.3'},
    {label: 'Aerium South Twr LT 6 No.5', value: 'Aerium South Twr LT 6 No.5'},
    {label: 'Aerium South Twr LT 7 No.10', value: 'Aerium South Twr LT 7 No.10'},
    {label: 'Aerium South Twr LT 7 No.11', value: 'Aerium South Twr LT 7 No.11'},
    {label: 'Aerium South Twr LT 8 No.10', value: 'Aerium South Twr LT 8 No.10'},
    {label: 'Aerium South Twr LT 8 No.5', value: 'Aerium South Twr LT 8 No.5'},
    {label: 'Aerium South Twr LT 8 No.7', value: 'Aerium South Twr LT 8 No.7'},
    {label: 'Aerium South Twr LT 9 No.10', value: 'Aerium South Twr LT 9 No.10'},
    {label: 'Aerium South Twr LT 9 No.3', value: 'Aerium South Twr LT 9 No.3'},
    {label: 'Aerium South Twr LT GF No.3', value: 'Aerium South Twr LT GF No.3'},
    {label: 'Aerium South Twr LT GF No.5', value: 'Aerium South Twr LT GF No.5'},
    {label: 'Aerium South Twr LT GF No.6', value: 'Aerium South Twr LT GF No.6'},
  ]

  let altueraUnitList = [
    {label: 'ALTUERA TOWER LT 10 No.H', value: 'ALTUERA TOWER LT 10 No.H'},
    {label: 'ALTUERA TOWER LT 10 No.M', value: 'ALTUERA TOWER LT 10 No.M'},
    {label: 'ALTUERA TOWER LT 10 No.P', value: 'ALTUERA TOWER LT 10 No.P'},
    {label: 'ALTUERA TOWER LT 10 No.R', value: 'ALTUERA TOWER LT 10 No.R'},
    {label: 'ALTUERA TOWER LT 11 No.F', value: 'ALTUERA TOWER LT 11 No.F'},
    {label: 'ALTUERA TOWER LT 11 No.H', value: 'ALTUERA TOWER LT 11 No.H'},
    {label: 'ALTUERA TOWER LT 11 No.J', value: 'ALTUERA TOWER LT 11 No.J'},
    {label: 'ALTUERA TOWER LT 11 No.P', value: 'ALTUERA TOWER LT 11 No.P'},
    {label: 'ALTUERA TOWER LT 11 No.S', value: 'ALTUERA TOWER LT 11 No.S'},
    {label: 'ALTUERA TOWER LT 12 No.B', value: 'ALTUERA TOWER LT 12 No.B'},
    {label: 'ALTUERA TOWER LT 12 No.C', value: 'ALTUERA TOWER LT 12 No.C'},
    {label: 'ALTUERA TOWER LT 12 No.D', value: 'ALTUERA TOWER LT 12 No.D'},
    {label: 'ALTUERA TOWER LT 12 No.E', value: 'ALTUERA TOWER LT 12 No.E'},
    {label: 'ALTUERA TOWER LT 12 No.F', value: 'ALTUERA TOWER LT 12 No.F'},
    {label: 'ALTUERA TOWER LT 12 No.G', value: 'ALTUERA TOWER LT 12 No.G'},
    {label: 'ALTUERA TOWER LT 12 No.H', value: 'ALTUERA TOWER LT 12 No.H'},
    {label: 'ALTUERA TOWER LT 12 No.I', value: 'ALTUERA TOWER LT 12 No.I'},
    {label: 'ALTUERA TOWER LT 12 No.J', value: 'ALTUERA TOWER LT 12 No.J'},
    {label: 'ALTUERA TOWER LT 12 No.K', value: 'ALTUERA TOWER LT 12 No.K'},
    {label: 'ALTUERA TOWER LT 12 No.L', value: 'ALTUERA TOWER LT 12 No.L'},
    {label: 'ALTUERA TOWER LT 12 No.M', value: 'ALTUERA TOWER LT 12 No.M'},
    {label: 'ALTUERA TOWER LT 12 No.N', value: 'ALTUERA TOWER LT 12 No.N'},
    {label: 'ALTUERA TOWER LT 12 No.O', value: 'ALTUERA TOWER LT 12 No.O'},
    {label: 'ALTUERA TOWER LT 12 No.P', value: 'ALTUERA TOWER LT 12 No.P'},
    {label: 'ALTUERA TOWER LT 12 No.Q', value: 'ALTUERA TOWER LT 12 No.Q'},
    {label: 'ALTUERA TOWER LT 12 No.S', value: 'ALTUERA TOWER LT 12 No.S'},
    {label: 'ALTUERA TOWER LT 12 No.T', value: 'ALTUERA TOWER LT 12 No.T'},
    {label: 'ALTUERA TOWER LT 12 No.V', value: 'ALTUERA TOWER LT 12 No.V'},
    {label: 'ALTUERA TOWER LT 12 No.W', value: 'ALTUERA TOWER LT 12 No.W'},
    {label: 'ALTUERA TOWER LT 12 No.Y', value: 'ALTUERA TOWER LT 12 No.Y'},
    {label: 'ALTUERA TOWER LT 15 No.A', value: 'ALTUERA TOWER LT 15 No.A'},
    {label: 'ALTUERA TOWER LT 15 No.B', value: 'ALTUERA TOWER LT 15 No.B'},
    {label: 'ALTUERA TOWER LT 15 No.C', value: 'ALTUERA TOWER LT 15 No.C'},
    {label: 'ALTUERA TOWER LT 15 No.D', value: 'ALTUERA TOWER LT 15 No.D'},
    {label: 'ALTUERA TOWER LT 15 No.E', value: 'ALTUERA TOWER LT 15 No.E'},
    {label: 'ALTUERA TOWER LT 15 No.F', value: 'ALTUERA TOWER LT 15 No.F'},
    {label: 'ALTUERA TOWER LT 15 No.G', value: 'ALTUERA TOWER LT 15 No.G'},
    {label: 'ALTUERA TOWER LT 15 No.H', value: 'ALTUERA TOWER LT 15 No.H'},
    {label: 'ALTUERA TOWER LT 15 No.I', value: 'ALTUERA TOWER LT 15 No.I'},
    {label: 'ALTUERA TOWER LT 15 No.J', value: 'ALTUERA TOWER LT 15 No.J'},
    {label: 'ALTUERA TOWER LT 15 No.K', value: 'ALTUERA TOWER LT 15 No.K'},
    {label: 'ALTUERA TOWER LT 15 No.L', value: 'ALTUERA TOWER LT 15 No.L'},
    {label: 'ALTUERA TOWER LT 15 No.M', value: 'ALTUERA TOWER LT 15 No.M'},
    {label: 'ALTUERA TOWER LT 15 No.N', value: 'ALTUERA TOWER LT 15 No.N'},
    {label: 'ALTUERA TOWER LT 15 No.O', value: 'ALTUERA TOWER LT 15 No.O'},
    {label: 'ALTUERA TOWER LT 15 No.P', value: 'ALTUERA TOWER LT 15 No.P'},
    {label: 'ALTUERA TOWER LT 15 No.Q', value: 'ALTUERA TOWER LT 15 No.Q'},
    {label: 'ALTUERA TOWER LT 15 No.R', value: 'ALTUERA TOWER LT 15 No.R'},
    {label: 'ALTUERA TOWER LT 15 No.S', value: 'ALTUERA TOWER LT 15 No.S'},
    {label: 'ALTUERA TOWER LT 15 No.T', value: 'ALTUERA TOWER LT 15 No.T'},
    {label: 'ALTUERA TOWER LT 15 No.U', value: 'ALTUERA TOWER LT 15 No.U'},
    {label: 'ALTUERA TOWER LT 15 No.V', value: 'ALTUERA TOWER LT 15 No.V'},
    {label: 'ALTUERA TOWER LT 15 No.W', value: 'ALTUERA TOWER LT 15 No.W'},
    {label: 'ALTUERA TOWER LT 15 No.Y', value: 'ALTUERA TOWER LT 15 No.Y'},
    {label: 'ALTUERA TOWER LT 16 No.B', value: 'ALTUERA TOWER LT 16 No.B'},
    {label: 'ALTUERA TOWER LT 16 No.C', value: 'ALTUERA TOWER LT 16 No.C'},
    {label: 'ALTUERA TOWER LT 16 No.H', value: 'ALTUERA TOWER LT 16 No.H'},
    {label: 'ALTUERA TOWER LT 16 No.J', value: 'ALTUERA TOWER LT 16 No.J'},
    {label: 'ALTUERA TOWER LT 16 No.L', value: 'ALTUERA TOWER LT 16 No.L'},
    {label: 'ALTUERA TOWER LT 16 No.M', value: 'ALTUERA TOWER LT 16 No.M'},
    {label: 'ALTUERA TOWER LT 16 No.Y', value: 'ALTUERA TOWER LT 16 No.Y'},
    {label: 'ALTUERA TOWER LT 17 No.B', value: 'ALTUERA TOWER LT 17 No.B'},
    {label: 'ALTUERA TOWER LT 17 No.E', value: 'ALTUERA TOWER LT 17 No.E'},
    {label: 'ALTUERA TOWER LT 17 No.J', value: 'ALTUERA TOWER LT 17 No.J'},
    {label: 'ALTUERA TOWER LT 17 No.L', value: 'ALTUERA TOWER LT 17 No.L'},
    {label: 'ALTUERA TOWER LT 17 No.O', value: 'ALTUERA TOWER LT 17 No.O'},
    {label: 'ALTUERA TOWER LT 17 No.Q', value: 'ALTUERA TOWER LT 17 No.Q'},
    {label: 'ALTUERA TOWER LT 18 No.C', value: 'ALTUERA TOWER LT 18 No.C'},
    {label: 'ALTUERA TOWER LT 18 No.D', value: 'ALTUERA TOWER LT 18 No.D'},
    {label: 'ALTUERA TOWER LT 18 No.E', value: 'ALTUERA TOWER LT 18 No.E'},
    {label: 'ALTUERA TOWER LT 18 No.H', value: 'ALTUERA TOWER LT 18 No.H'},
    {label: 'ALTUERA TOWER LT 18 No.J', value: 'ALTUERA TOWER LT 18 No.J'},
    {label: 'ALTUERA TOWER LT 18 No.K', value: 'ALTUERA TOWER LT 18 No.K'},
    {label: 'ALTUERA TOWER LT 18 No.O', value: 'ALTUERA TOWER LT 18 No.O'},
    {label: 'ALTUERA TOWER LT 19 No.A', value: 'ALTUERA TOWER LT 19 No.A'},
    {label: 'ALTUERA TOWER LT 19 No.B', value: 'ALTUERA TOWER LT 19 No.B'},
    {label: 'ALTUERA TOWER LT 19 No.C', value: 'ALTUERA TOWER LT 19 No.C'},
    {label: 'ALTUERA TOWER LT 19 No.D', value: 'ALTUERA TOWER LT 19 No.D'},
    {label: 'ALTUERA TOWER LT 19 No.E', value: 'ALTUERA TOWER LT 19 No.E'},
    {label: 'ALTUERA TOWER LT 19 No.F', value: 'ALTUERA TOWER LT 19 No.F'},
    {label: 'ALTUERA TOWER LT 19 No.G', value: 'ALTUERA TOWER LT 19 No.G'},
    {label: 'ALTUERA TOWER LT 19 No.H', value: 'ALTUERA TOWER LT 19 No.H'},
    {label: 'ALTUERA TOWER LT 19 No.I', value: 'ALTUERA TOWER LT 19 No.I'},
    {label: 'ALTUERA TOWER LT 19 No.J', value: 'ALTUERA TOWER LT 19 No.J'},
    {label: 'ALTUERA TOWER LT 19 No.K', value: 'ALTUERA TOWER LT 19 No.K'},
    {label: 'ALTUERA TOWER LT 19 No.L', value: 'ALTUERA TOWER LT 19 No.L'},
    {label: 'ALTUERA TOWER LT 19 No.M', value: 'ALTUERA TOWER LT 19 No.M'},
    {label: 'ALTUERA TOWER LT 19 No.N', value: 'ALTUERA TOWER LT 19 No.N'},
    {label: 'ALTUERA TOWER LT 19 No.O', value: 'ALTUERA TOWER LT 19 No.O'},
    {label: 'ALTUERA TOWER LT 19 No.P', value: 'ALTUERA TOWER LT 19 No.P'},
    {label: 'ALTUERA TOWER LT 19 No.Q', value: 'ALTUERA TOWER LT 19 No.Q'},
    {label: 'ALTUERA TOWER LT 19 No.R', value: 'ALTUERA TOWER LT 19 No.R'},
    {label: 'ALTUERA TOWER LT 19 No.S', value: 'ALTUERA TOWER LT 19 No.S'},
    {label: 'ALTUERA TOWER LT 19 No.T', value: 'ALTUERA TOWER LT 19 No.T'},
    {label: 'ALTUERA TOWER LT 19 No.U', value: 'ALTUERA TOWER LT 19 No.U'},
    {label: 'ALTUERA TOWER LT 19 No.V', value: 'ALTUERA TOWER LT 19 No.V'},
    {label: 'ALTUERA TOWER LT 19 No.W', value: 'ALTUERA TOWER LT 19 No.W'},
    {label: 'ALTUERA TOWER LT 19 No.X', value: 'ALTUERA TOWER LT 19 No.X'},
    {label: 'ALTUERA TOWER LT 19 No.Y', value: 'ALTUERA TOWER LT 19 No.Y'},
    {label: 'ALTUERA TOWER LT 20 No.B', value: 'ALTUERA TOWER LT 20 No.B'},
    {label: 'ALTUERA TOWER LT 20 No.E', value: 'ALTUERA TOWER LT 20 No.E'},
    {label: 'ALTUERA TOWER LT 20 No.J', value: 'ALTUERA TOWER LT 20 No.J'},
    {label: 'ALTUERA TOWER LT 20 No.L', value: 'ALTUERA TOWER LT 20 No.L'},
    {label: 'ALTUERA TOWER LT 21 No.A', value: 'ALTUERA TOWER LT 21 No.A'},
    {label: 'ALTUERA TOWER LT 21 No.B', value: 'ALTUERA TOWER LT 21 No.B'},
    {label: 'ALTUERA TOWER LT 21 No.C', value: 'ALTUERA TOWER LT 21 No.C'},
    {label: 'ALTUERA TOWER LT 21 No.D', value: 'ALTUERA TOWER LT 21 No.D'},
    {label: 'ALTUERA TOWER LT 21 No.E', value: 'ALTUERA TOWER LT 21 No.E'},
    {label: 'ALTUERA TOWER LT 21 No.F', value: 'ALTUERA TOWER LT 21 No.F'},
    {label: 'ALTUERA TOWER LT 21 No.G', value: 'ALTUERA TOWER LT 21 No.G'},
    {label: 'ALTUERA TOWER LT 21 No.H', value: 'ALTUERA TOWER LT 21 No.H'},
    {label: 'ALTUERA TOWER LT 21 No.I', value: 'ALTUERA TOWER LT 21 No.I'},
    {label: 'ALTUERA TOWER LT 21 No.J', value: 'ALTUERA TOWER LT 21 No.J'},
    {label: 'ALTUERA TOWER LT 21 No.K', value: 'ALTUERA TOWER LT 21 No.K'},
    {label: 'ALTUERA TOWER LT 21 No.L', value: 'ALTUERA TOWER LT 21 No.L'},
    {label: 'ALTUERA TOWER LT 21 No.M', value: 'ALTUERA TOWER LT 21 No.M'},
    {label: 'ALTUERA TOWER LT 21 No.N', value: 'ALTUERA TOWER LT 21 No.N'},
    {label: 'ALTUERA TOWER LT 21 No.O', value: 'ALTUERA TOWER LT 21 No.O'},
    {label: 'ALTUERA TOWER LT 21 No.P', value: 'ALTUERA TOWER LT 21 No.P'},
    {label: 'ALTUERA TOWER LT 21 No.Q', value: 'ALTUERA TOWER LT 21 No.Q'},
    {label: 'ALTUERA TOWER LT 21 No.R', value: 'ALTUERA TOWER LT 21 No.R'},
    {label: 'ALTUERA TOWER LT 21 No.S', value: 'ALTUERA TOWER LT 21 No.S'},
    {label: 'ALTUERA TOWER LT 21 No.T', value: 'ALTUERA TOWER LT 21 No.T'},
    {label: 'ALTUERA TOWER LT 21 No.U', value: 'ALTUERA TOWER LT 21 No.U'},
    {label: 'ALTUERA TOWER LT 21 No.V', value: 'ALTUERA TOWER LT 21 No.V'},
    {label: 'ALTUERA TOWER LT 21 No.W', value: 'ALTUERA TOWER LT 21 No.W'},
    {label: 'ALTUERA TOWER LT 21 No.X', value: 'ALTUERA TOWER LT 21 No.X'},
    {label: 'ALTUERA TOWER LT 21 No.Y', value: 'ALTUERA TOWER LT 21 No.Y'},
    {label: 'ALTUERA TOWER LT 22 No.A', value: 'ALTUERA TOWER LT 22 No.A'},
    {label: 'ALTUERA TOWER LT 22 No.B', value: 'ALTUERA TOWER LT 22 No.B'},
    {label: 'ALTUERA TOWER LT 22 No.C', value: 'ALTUERA TOWER LT 22 No.C'},
    {label: 'ALTUERA TOWER LT 22 No.D', value: 'ALTUERA TOWER LT 22 No.D'},
    {label: 'ALTUERA TOWER LT 22 No.E', value: 'ALTUERA TOWER LT 22 No.E'},
    {label: 'ALTUERA TOWER LT 22 No.F', value: 'ALTUERA TOWER LT 22 No.F'},
    {label: 'ALTUERA TOWER LT 22 No.G', value: 'ALTUERA TOWER LT 22 No.G'},
    {label: 'ALTUERA TOWER LT 22 No.H', value: 'ALTUERA TOWER LT 22 No.H'},
    {label: 'ALTUERA TOWER LT 22 No.I', value: 'ALTUERA TOWER LT 22 No.I'},
    {label: 'ALTUERA TOWER LT 22 No.J', value: 'ALTUERA TOWER LT 22 No.J'},
    {label: 'ALTUERA TOWER LT 22 No.K', value: 'ALTUERA TOWER LT 22 No.K'},
    {label: 'ALTUERA TOWER LT 22 No.L', value: 'ALTUERA TOWER LT 22 No.L'},
    {label: 'ALTUERA TOWER LT 22 No.M', value: 'ALTUERA TOWER LT 22 No.M'},
    {label: 'ALTUERA TOWER LT 22 No.N', value: 'ALTUERA TOWER LT 22 No.N'},
    {label: 'ALTUERA TOWER LT 22 No.O', value: 'ALTUERA TOWER LT 22 No.O'},
    {label: 'ALTUERA TOWER LT 22 No.P', value: 'ALTUERA TOWER LT 22 No.P'},
    {label: 'ALTUERA TOWER LT 22 No.Q', value: 'ALTUERA TOWER LT 22 No.Q'},
    {label: 'ALTUERA TOWER LT 22 No.R', value: 'ALTUERA TOWER LT 22 No.R'},
    {label: 'ALTUERA TOWER LT 22 No.S', value: 'ALTUERA TOWER LT 22 No.S'},
    {label: 'ALTUERA TOWER LT 22 No.T', value: 'ALTUERA TOWER LT 22 No.T'},
    {label: 'ALTUERA TOWER LT 22 No.U', value: 'ALTUERA TOWER LT 22 No.U'},
    {label: 'ALTUERA TOWER LT 22 No.V', value: 'ALTUERA TOWER LT 22 No.V'},
    {label: 'ALTUERA TOWER LT 22 No.W', value: 'ALTUERA TOWER LT 22 No.W'},
    {label: 'ALTUERA TOWER LT 22 No.X', value: 'ALTUERA TOWER LT 22 No.X'},
    {label: 'ALTUERA TOWER LT 22 No.Y', value: 'ALTUERA TOWER LT 22 No.Y'},
    {label: 'ALTUERA TOWER LT 23 No.A', value: 'ALTUERA TOWER LT 23 No.A'},
    {label: 'ALTUERA TOWER LT 23 No.B', value: 'ALTUERA TOWER LT 23 No.B'},
    {label: 'ALTUERA TOWER LT 23 No.C', value: 'ALTUERA TOWER LT 23 No.C'},
    {label: 'ALTUERA TOWER LT 23 No.D', value: 'ALTUERA TOWER LT 23 No.D'},
    {label: 'ALTUERA TOWER LT 23 No.E', value: 'ALTUERA TOWER LT 23 No.E'},
    {label: 'ALTUERA TOWER LT 23 No.F', value: 'ALTUERA TOWER LT 23 No.F'},
    {label: 'ALTUERA TOWER LT 23 No.G', value: 'ALTUERA TOWER LT 23 No.G'},
    {label: 'ALTUERA TOWER LT 23 No.H', value: 'ALTUERA TOWER LT 23 No.H'},
    {label: 'ALTUERA TOWER LT 23 No.J', value: 'ALTUERA TOWER LT 23 No.J'},
    {label: 'ALTUERA TOWER LT 23 No.K', value: 'ALTUERA TOWER LT 23 No.K'},
    {label: 'ALTUERA TOWER LT 23 No.M', value: 'ALTUERA TOWER LT 23 No.M'},
    {label: 'ALTUERA TOWER LT 23 No.N', value: 'ALTUERA TOWER LT 23 No.N'},
    {label: 'ALTUERA TOWER LT 23 No.Q', value: 'ALTUERA TOWER LT 23 No.Q'},
    {label: 'ALTUERA TOWER LT 23 No.R', value: 'ALTUERA TOWER LT 23 No.R'},
    {label: 'ALTUERA TOWER LT 23 No.T', value: 'ALTUERA TOWER LT 23 No.T'},
    {label: 'ALTUERA TOWER LT 23 No.U', value: 'ALTUERA TOWER LT 23 No.U'},
    {label: 'ALTUERA TOWER LT 23 No.V', value: 'ALTUERA TOWER LT 23 No.V'},
    {label: 'ALTUERA TOWER LT 23 No.Y', value: 'ALTUERA TOWER LT 23 No.Y'},
    {label: 'ALTUERA TOWER LT 25 No.H', value: 'ALTUERA TOWER LT 25 No.H'},
    {label: 'ALTUERA TOWER LT 25 No.N', value: 'ALTUERA TOWER LT 25 No.N'},
    {label: 'ALTUERA TOWER LT 25 No.O', value: 'ALTUERA TOWER LT 25 No.O'},
    {label: 'ALTUERA TOWER LT 26 No.A', value: 'ALTUERA TOWER LT 26 No.A'},
    {label: 'ALTUERA TOWER LT 26 No.B', value: 'ALTUERA TOWER LT 26 No.B'},
    {label: 'ALTUERA TOWER LT 26 No.C', value: 'ALTUERA TOWER LT 26 No.C'},
    {label: 'ALTUERA TOWER LT 26 No.D', value: 'ALTUERA TOWER LT 26 No.D'},
    {label: 'ALTUERA TOWER LT 26 No.E', value: 'ALTUERA TOWER LT 26 No.E'},
    {label: 'ALTUERA TOWER LT 26 No.F', value: 'ALTUERA TOWER LT 26 No.F'},
    {label: 'ALTUERA TOWER LT 26 No.G', value: 'ALTUERA TOWER LT 26 No.G'},
    {label: 'ALTUERA TOWER LT 26 No.H', value: 'ALTUERA TOWER LT 26 No.H'},
    {label: 'ALTUERA TOWER LT 26 No.I', value: 'ALTUERA TOWER LT 26 No.I'},
    {label: 'ALTUERA TOWER LT 26 No.J', value: 'ALTUERA TOWER LT 26 No.J'},
    {label: 'ALTUERA TOWER LT 26 No.K', value: 'ALTUERA TOWER LT 26 No.K'},
    {label: 'ALTUERA TOWER LT 26 No.L', value: 'ALTUERA TOWER LT 26 No.L'},
    {label: 'ALTUERA TOWER LT 26 No.M', value: 'ALTUERA TOWER LT 26 No.M'},
    {label: 'ALTUERA TOWER LT 26 No.N', value: 'ALTUERA TOWER LT 26 No.N'},
    {label: 'ALTUERA TOWER LT 26 No.O', value: 'ALTUERA TOWER LT 26 No.O'},
    {label: 'ALTUERA TOWER LT 26 No.Q', value: 'ALTUERA TOWER LT 26 No.Q'},
    {label: 'ALTUERA TOWER LT 26 No.R', value: 'ALTUERA TOWER LT 26 No.R'},
    {label: 'ALTUERA TOWER LT 26 No.S', value: 'ALTUERA TOWER LT 26 No.S'},
    {label: 'ALTUERA TOWER LT 26 No.T', value: 'ALTUERA TOWER LT 26 No.T'},
    {label: 'ALTUERA TOWER LT 26 No.U', value: 'ALTUERA TOWER LT 26 No.U'},
    {label: 'ALTUERA TOWER LT 26 No.V', value: 'ALTUERA TOWER LT 26 No.V'},
    {label: 'ALTUERA TOWER LT 26 No.Y', value: 'ALTUERA TOWER LT 26 No.Y'},
    {label: 'ALTUERA TOWER LT 27 No.H', value: 'ALTUERA TOWER LT 27 No.H'},
    {label: 'ALTUERA TOWER LT 27 No.I', value: 'ALTUERA TOWER LT 27 No.I'},
    {label: 'ALTUERA TOWER LT 27 No.N', value: 'ALTUERA TOWER LT 27 No.N'},
    {label: 'ALTUERA TOWER LT 27 No.O', value: 'ALTUERA TOWER LT 27 No.O'},
    {label: 'ALTUERA TOWER LT 27 No.V', value: 'ALTUERA TOWER LT 27 No.V'},
    {label: 'ALTUERA TOWER LT 27 No.Y', value: 'ALTUERA TOWER LT 27 No.Y'},
    {label: 'ALTUERA TOWER LT 5 No.D', value: 'ALTUERA TOWER LT 5 No.D'},
    {label: 'ALTUERA TOWER LT 5 No.E', value: 'ALTUERA TOWER LT 5 No.E'},
    {label: 'ALTUERA TOWER LT 5 No.F', value: 'ALTUERA TOWER LT 5 No.F'},
    {label: 'ALTUERA TOWER LT 5 No.G', value: 'ALTUERA TOWER LT 5 No.G'},
    {label: 'ALTUERA TOWER LT 5 No.O', value: 'ALTUERA TOWER LT 5 No.O'},
    {label: 'ALTUERA TOWER LT 6 No.D', value: 'ALTUERA TOWER LT 6 No.D'},
    {label: 'ALTUERA TOWER LT 6 No.E', value: 'ALTUERA TOWER LT 6 No.E'},
    {label: 'ALTUERA TOWER LT 6 No.F', value: 'ALTUERA TOWER LT 6 No.F'},
    {label: 'ALTUERA TOWER LT 6 No.G', value: 'ALTUERA TOWER LT 6 No.G'},
    {label: 'ALTUERA TOWER LT 6 No.O', value: 'ALTUERA TOWER LT 6 No.O'},
    {label: 'ALTUERA TOWER LT 7 No.E', value: 'ALTUERA TOWER LT 7 No.E'},
    {label: 'ALTUERA TOWER LT 7 No.F', value: 'ALTUERA TOWER LT 7 No.F'},
    {label: 'ALTUERA TOWER LT 7 No.G', value: 'ALTUERA TOWER LT 7 No.G'},
    {label: 'ALTUERA TOWER LT 7 No.O', value: 'ALTUERA TOWER LT 7 No.O'},
    {label: 'ALTUERA TOWER LT 9 No.H', value: 'ALTUERA TOWER LT 9 No.H'},
    {label: 'ALTUERA TOWER LT 9 No.P', value: 'ALTUERA TOWER LT 9 No.P'},
  ]

  let eleganceUnitList = [
    {label: 'ELEGANCE TOWER LT 2 No.A', value: 'ELEGANCE TOWER LT 2 No.A'},
    {label: 'ELEGANCE TOWER LT 2 No.D', value: 'ELEGANCE TOWER LT 2 No.D'},
    {label: 'ELEGANCE TOWER LT 2 No.F', value: 'ELEGANCE TOWER LT 2 No.F'},
    {label: 'ELEGANCE TOWER LT 3 No.A', value: 'ELEGANCE TOWER LT 3 No.A'},
    {label: 'ELEGANCE TOWER LT 3 No.B', value: 'ELEGANCE TOWER LT 3 No.B'},
    {label: 'ELEGANCE TOWER LT 3 No.D', value: 'ELEGANCE TOWER LT 3 No.D'},
    {label: 'ELEGANCE TOWER LT 3 No.F', value: 'ELEGANCE TOWER LT 3 No.F'},
    {label: 'ELEGANCE TOWER LT 3 No.K', value: 'ELEGANCE TOWER LT 3 No.K'},
    {label: 'ELEGANCE TOWER LT 5 No.A', value: 'ELEGANCE TOWER LT 5 No.A'},
    {label: 'ELEGANCE TOWER LT 6 No.A', value: 'ELEGANCE TOWER LT 6 No.A'},
    {label: 'ELEGANCE TOWER LT 6 No.C', value: 'ELEGANCE TOWER LT 6 No.C'},
    {label: 'ELEGANCE TOWER LT 6 No.D', value: 'ELEGANCE TOWER LT 6 No.D'},
    {label: 'ELEGANCE TOWER LT 6 No.F', value: 'ELEGANCE TOWER LT 6 No.F'},
    {label: 'ELEGANCE TOWER LT 7 No.B', value: 'ELEGANCE TOWER LT 7 No.B'},
    {label: 'ELEGANCE TOWER LT 7 No.D', value: 'ELEGANCE TOWER LT 7 No.D'},
    {label: 'ELEGANCE TOWER LT 7 No.F', value: 'ELEGANCE TOWER LT 7 No.F'},
    {label: 'ELEGANCE TOWER LT 8 No.F', value: 'ELEGANCE TOWER LT 8 No.F'},
    {label: 'ELEGANCE TOWER LT 9 No.A', value: 'ELEGANCE TOWER LT 9 No.A'},
    {label: 'ELEGANCE TOWER LT 9 No.F', value: 'ELEGANCE TOWER LT 9 No.F'},
    {label: 'ELEGANCE TOWER LT 10 No.B', value: 'ELEGANCE TOWER LT 10 No.B'},
    {label: 'ELEGANCE TOWER LT 10 No.D', value: 'ELEGANCE TOWER LT 10 No.D'},
    {label: 'ELEGANCE TOWER LT 10 No.K', value: 'ELEGANCE TOWER LT 10 No.K'},
    {label: 'ELEGANCE TOWER LT 11 No.A', value: 'ELEGANCE TOWER LT 11 No.A'},
    {label: 'ELEGANCE TOWER LT 11 No.B', value: 'ELEGANCE TOWER LT 11 No.B'},
    {label: 'ELEGANCE TOWER LT 11 No.D', value: 'ELEGANCE TOWER LT 11 No.D'},
    {label: 'ELEGANCE TOWER LT 16 No.B', value: 'ELEGANCE TOWER LT 16 No.B'},
    {label: 'ELEGANCE TOWER LT 16 No.D', value: 'ELEGANCE TOWER LT 16 No.D'},
    {label: 'ELEGANCE TOWER LT 16 No.F', value: 'ELEGANCE TOWER LT 16 No.F'},
    {label: 'ELEGANCE TOWER LT 16 No.K', value: 'ELEGANCE TOWER LT 16 No.K'},
    {label: 'ELEGANCE TOWER LT 17 No.B', value: 'ELEGANCE TOWER LT 17 No.B'},
    {label: 'ELEGANCE TOWER LT 17 No.F', value: 'ELEGANCE TOWER LT 17 No.F'},
    {label: 'ELEGANCE TOWER LT 17 No.K', value: 'ELEGANCE TOWER LT 17 No.K'},
    {label: 'ELEGANCE TOWER LT 18 No.C', value: 'ELEGANCE TOWER LT 18 No.C'},
    {label: 'ELEGANCE TOWER LT 19 No.B', value: 'ELEGANCE TOWER LT 19 No.B'},
    {label: 'ELEGANCE TOWER LT 19 No.F', value: 'ELEGANCE TOWER LT 19 No.F'},
    {label: 'ELEGANCE TOWER LT 19 No.K', value: 'ELEGANCE TOWER LT 19 No.K'},
    {label: 'ELEGANCE TOWER LT 20 No.A', value: 'ELEGANCE TOWER LT 20 No.A'},
    {label: 'ELEGANCE TOWER LT 20 No.B', value: 'ELEGANCE TOWER LT 20 No.B'},
    {label: 'ELEGANCE TOWER LT 20 No.C', value: 'ELEGANCE TOWER LT 20 No.C'},
    {label: 'ELEGANCE TOWER LT 21 No.F', value: 'ELEGANCE TOWER LT 21 No.F'},
    {label: 'ELEGANCE TOWER LT 22 No.A', value: 'ELEGANCE TOWER LT 22 No.A'},
    {label: 'ELEGANCE TOWER LT 22 No.B', value: 'ELEGANCE TOWER LT 22 No.B'},
    {label: 'ELEGANCE TOWER LT 22 No.C', value: 'ELEGANCE TOWER LT 22 No.C'},
    {label: 'ELEGANCE TOWER LT 22 No.D', value: 'ELEGANCE TOWER LT 22 No.D'},
    {label: 'ELEGANCE TOWER LT 22 No.F', value: 'ELEGANCE TOWER LT 22 No.F'},
    {label: 'ELEGANCE TOWER LT 22 No.K', value: 'ELEGANCE TOWER LT 22 No.K'},
    {label: 'ELEGANCE TOWER LT 23 No.B', value: 'ELEGANCE TOWER LT 23 No.B'},
    {label: 'ELEGANCE TOWER LT 23 No.C', value: 'ELEGANCE TOWER LT 23 No.C'},
    {label: 'ELEGANCE TOWER LT 23 No.D', value: 'ELEGANCE TOWER LT 23 No.D'},
    {label: 'ELEGANCE TOWER LT 23 No.F', value: 'ELEGANCE TOWER LT 23 No.F'},
    {label: 'ELEGANCE TOWER LT 25 No.C', value: 'ELEGANCE TOWER LT 25 No.C'},
    {label: 'ELEGANCE TOWER LT 25 No.D', value: 'ELEGANCE TOWER LT 25 No.D'},
    {label: 'ELEGANCE TOWER LT 25 No.F', value: 'ELEGANCE TOWER LT 25 No.F'},
    {label: 'ELEGANCE TOWER LT 25 No.K', value: 'ELEGANCE TOWER LT 25 No.K'},
    {label: 'ELEGANCE TOWER LT 26 No.B', value: 'ELEGANCE TOWER LT 26 No.B'},
    {label: 'ELEGANCE TOWER LT 26 No.C', value: 'ELEGANCE TOWER LT 26 No.C'},
    {label: 'ELEGANCE TOWER LT 26 No.D', value: 'ELEGANCE TOWER LT 26 No.D'},
    {label: 'ELEGANCE TOWER LT 26 No.F', value: 'ELEGANCE TOWER LT 26 No.F'},
    {label: 'ELEGANCE TOWER LT 26 No.K', value: 'ELEGANCE TOWER LT 26 No.K'},
    {label: 'ELEGANCE TOWER LT 27 No.C', value: 'ELEGANCE TOWER LT 27 No.C'},
    {label: 'ELEGANCE TOWER LT 27 No.D', value: 'ELEGANCE TOWER LT 27 No.D'},
    {label: 'ELEGANCE TOWER LT 27 No.F', value: 'ELEGANCE TOWER LT 27 No.F'},
    {label: 'ELEGANCE TOWER LT 27 No.K', value: 'ELEGANCE TOWER LT 27 No.K'},
    {label: 'ELEGANCE TOWER LT 28 No.B', value: 'ELEGANCE TOWER LT 28 No.B'},
    {label: 'ELEGANCE TOWER LT 28 No.C', value: 'ELEGANCE TOWER LT 28 No.C'},
    {label: 'ELEGANCE TOWER LT 28 No.D', value: 'ELEGANCE TOWER LT 28 No.D'},
    {label: 'ELEGANCE TOWER LT 28 No.F', value: 'ELEGANCE TOWER LT 28 No.F'},
    {label: 'ELEGANCE TOWER LT 29 No.A', value: 'ELEGANCE TOWER LT 29 No.A'},
    {label: 'ELEGANCE TOWER LT 29 No.B', value: 'ELEGANCE TOWER LT 29 No.B'},
    {label: 'ELEGANCE TOWER LT 29 No.C', value: 'ELEGANCE TOWER LT 29 No.C'},
    {label: 'ELEGANCE TOWER LT 29 No.D', value: 'ELEGANCE TOWER LT 29 No.D'},
    {label: 'ELEGANCE TOWER LT 29 No.F', value: 'ELEGANCE TOWER LT 29 No.F'},
    {label: 'ELEGANCE TOWER LT 29 No.K', value: 'ELEGANCE TOWER LT 29 No.K'},
    {label: 'ELEGANCE TOWER LT 30 No.B', value: 'ELEGANCE TOWER LT 30 No.B'},
    {label: 'ELEGANCE TOWER LT 30 No.C', value: 'ELEGANCE TOWER LT 30 No.C'},
    {label: 'ELEGANCE TOWER LT 30 No.D', value: 'ELEGANCE TOWER LT 30 No.D'},
    {label: 'ELEGANCE TOWER LT 30 No.F', value: 'ELEGANCE TOWER LT 30 No.F'},
    {label: 'ELEGANCE TOWER LT 31 No.B', value: 'ELEGANCE TOWER LT 31 No.B'},
    {label: 'ELEGANCE TOWER LT 31 No.C', value: 'ELEGANCE TOWER LT 31 No.C'},
    {label: 'ELEGANCE TOWER LT 31 No.F', value: 'ELEGANCE TOWER LT 31 No.F'},
  ]

  let elementsUnitList = [
    {label: 'ELEMENTS TOWER 1 LT 10 No.B', value: 'ELEMENTS TOWER 1 LT 10 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 10 No.C', value: 'ELEMENTS TOWER 1 LT 10 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 10 No.D', value: 'ELEMENTS TOWER 1 LT 10 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 11 No.A', value: 'ELEMENTS TOWER 1 LT 11 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 11 No.D', value: 'ELEMENTS TOWER 1 LT 11 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 12 No.C', value: 'ELEMENTS TOWER 1 LT 12 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 12 No.D', value: 'ELEMENTS TOWER 1 LT 12 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 15 No.C', value: 'ELEMENTS TOWER 1 LT 15 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 15 No.D', value: 'ELEMENTS TOWER 1 LT 15 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 16 No.C', value: 'ELEMENTS TOWER 1 LT 16 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 17 No.B', value: 'ELEMENTS TOWER 1 LT 17 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 17 No.C', value: 'ELEMENTS TOWER 1 LT 17 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 17 No.D', value: 'ELEMENTS TOWER 1 LT 17 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 18 No.C', value: 'ELEMENTS TOWER 1 LT 18 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 18 No.D', value: 'ELEMENTS TOWER 1 LT 18 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 19 No.B', value: 'ELEMENTS TOWER 1 LT 19 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 19 No.C', value: 'ELEMENTS TOWER 1 LT 19 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 19 No.D', value: 'ELEMENTS TOWER 1 LT 19 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 20 No.C', value: 'ELEMENTS TOWER 1 LT 20 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 21 No.C', value: 'ELEMENTS TOWER 1 LT 21 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 23 No.C', value: 'ELEMENTS TOWER 1 LT 23 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 25 No.C', value: 'ELEMENTS TOWER 1 LT 25 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 26 No.C', value: 'ELEMENTS TOWER 1 LT 26 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 27 No.C', value: 'ELEMENTS TOWER 1 LT 27 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 27 No.D', value: 'ELEMENTS TOWER 1 LT 27 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 29 No.C', value: 'ELEMENTS TOWER 1 LT 29 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 3 No.A', value: 'ELEMENTS TOWER 1 LT 3 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 3 No.B', value: 'ELEMENTS TOWER 1 LT 3 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 3 No.C', value: 'ELEMENTS TOWER 1 LT 3 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 3 No.D', value: 'ELEMENTS TOWER 1 LT 3 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 31 No.C', value: 'ELEMENTS TOWER 1 LT 31 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 31 No.D', value: 'ELEMENTS TOWER 1 LT 31 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 32 No.C', value: 'ELEMENTS TOWER 1 LT 32 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 32 No.D', value: 'ELEMENTS TOWER 1 LT 32 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 33 No.C', value: 'ELEMENTS TOWER 1 LT 33 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 35 No.C', value: 'ELEMENTS TOWER 1 LT 35 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 36 No.B', value: 'ELEMENTS TOWER 1 LT 36 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 36 No.C', value: 'ELEMENTS TOWER 1 LT 36 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 37 No.C', value: 'ELEMENTS TOWER 1 LT 37 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 5 No.A', value: 'ELEMENTS TOWER 1 LT 5 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 5 No.B', value: 'ELEMENTS TOWER 1 LT 5 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 5 No.C', value: 'ELEMENTS TOWER 1 LT 5 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 5 No.D', value: 'ELEMENTS TOWER 1 LT 5 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 6 No.A', value: 'ELEMENTS TOWER 1 LT 6 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 6 No.B', value: 'ELEMENTS TOWER 1 LT 6 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 6 No.C', value: 'ELEMENTS TOWER 1 LT 6 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 6 No.D', value: 'ELEMENTS TOWER 1 LT 6 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 7 No.A', value: 'ELEMENTS TOWER 1 LT 7 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 7 No.B', value: 'ELEMENTS TOWER 1 LT 7 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 7 No.C', value: 'ELEMENTS TOWER 1 LT 7 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 7 No.D', value: 'ELEMENTS TOWER 1 LT 7 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 8 No.A', value: 'ELEMENTS TOWER 1 LT 8 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 8 No.C', value: 'ELEMENTS TOWER 1 LT 8 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 8 No.D', value: 'ELEMENTS TOWER 1 LT 8 No.D'},
    {label: 'ELEMENTS TOWER 1 LT 9 No.A', value: 'ELEMENTS TOWER 1 LT 9 No.A'},
    {label: 'ELEMENTS TOWER 1 LT 9 No.B', value: 'ELEMENTS TOWER 1 LT 9 No.B'},
    {label: 'ELEMENTS TOWER 1 LT 9 No.C', value: 'ELEMENTS TOWER 1 LT 9 No.C'},
    {label: 'ELEMENTS TOWER 1 LT 9 No.D', value: 'ELEMENTS TOWER 1 LT 9 No.D'},
    {label: 'ELEMENTS TOWER 2 LT 10 No.A', value: 'ELEMENTS TOWER 2 LT 10 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 12 No.A', value: 'ELEMENTS TOWER 2 LT 12 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 12 No.B', value: 'ELEMENTS TOWER 2 LT 12 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 15 No.A', value: 'ELEMENTS TOWER 2 LT 15 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 15 No.B', value: 'ELEMENTS TOWER 2 LT 15 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 15 No.F', value: 'ELEMENTS TOWER 2 LT 15 No.F'},
    {label: 'ELEMENTS TOWER 2 LT 16 No.A', value: 'ELEMENTS TOWER 2 LT 16 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 17 No.A', value: 'ELEMENTS TOWER 2 LT 17 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 17 No.B', value: 'ELEMENTS TOWER 2 LT 17 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 22 No.B', value: 'ELEMENTS TOWER 2 LT 22 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 23 No.B', value: 'ELEMENTS TOWER 2 LT 23 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 25 No.B', value: 'ELEMENTS TOWER 2 LT 25 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 29 No.B', value: 'ELEMENTS TOWER 2 LT 29 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 29 No.E', value: 'ELEMENTS TOWER 2 LT 29 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 30 No.B', value: 'ELEMENTS TOWER 2 LT 30 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 31 No.B', value: 'ELEMENTS TOWER 2 LT 31 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 31 No.E', value: 'ELEMENTS TOWER 2 LT 31 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 32 No.B', value: 'ELEMENTS TOWER 2 LT 32 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 32 No.E', value: 'ELEMENTS TOWER 2 LT 32 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 33 No.B', value: 'ELEMENTS TOWER 2 LT 33 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 35 No.B', value: 'ELEMENTS TOWER 2 LT 35 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 35 No.E', value: 'ELEMENTS TOWER 2 LT 35 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 36 No.B', value: 'ELEMENTS TOWER 2 LT 36 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 36 No.E', value: 'ELEMENTS TOWER 2 LT 36 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 37 No.E', value: 'ELEMENTS TOWER 2 LT 37 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 38 No.B', value: 'ELEMENTS TOWER 2 LT 38 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 38 No.E', value: 'ELEMENTS TOWER 2 LT 38 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 39 No.B', value: 'ELEMENTS TOWER 2 LT 39 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 40 No.A', value: 'ELEMENTS TOWER 2 LT 40 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 40 No.B', value: 'ELEMENTS TOWER 2 LT 40 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 40 No.C', value: 'ELEMENTS TOWER 2 LT 40 No.C'},
    {label: 'ELEMENTS TOWER 2 LT 40 No.D', value: 'ELEMENTS TOWER 2 LT 40 No.D'},
    {label: 'ELEMENTS TOWER 2 LT 40 No.E', value: 'ELEMENTS TOWER 2 LT 40 No.E'},
    {label: 'ELEMENTS TOWER 2 LT 41 No.A', value: 'ELEMENTS TOWER 2 LT 41 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 41 No.B', value: 'ELEMENTS TOWER 2 LT 41 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 41 No.C', value: 'ELEMENTS TOWER 2 LT 41 No.C'},
    {label: 'ELEMENTS TOWER 2 LT 42 No.A', value: 'ELEMENTS TOWER 2 LT 42 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 42 No.B', value: 'ELEMENTS TOWER 2 LT 42 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 43 No.A', value: 'ELEMENTS TOWER 2 LT 43 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 43 No.B', value: 'ELEMENTS TOWER 2 LT 43 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 43 No.C', value: 'ELEMENTS TOWER 2 LT 43 No.C'},
    {label: 'ELEMENTS TOWER 2 LT 45 No.A', value: 'ELEMENTS TOWER 2 LT 45 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 45 No.B', value: 'ELEMENTS TOWER 2 LT 45 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 5 No.A', value: 'ELEMENTS TOWER 2 LT 5 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 5 No.B', value: 'ELEMENTS TOWER 2 LT 5 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 6 No.A', value: 'ELEMENTS TOWER 2 LT 6 No.A'},
    {label: 'ELEMENTS TOWER 2 LT 7 No.B', value: 'ELEMENTS TOWER 2 LT 7 No.B'},
    {label: 'ELEMENTS TOWER 2 LT 9 No.B', value: 'ELEMENTS TOWER 2 LT 9 No.B'},
  ]

  let klaskaUnitList = [
    {label: 'KLASKA AZURE Lantai 11 No. 22', value: 'KLASKA AZURE Lantai 11 No. 22'},
    {label: 'KLASKA AZURE Lantai 11 No. 36', value: 'KLASKA AZURE Lantai 11 No. 36'},
    {label: 'KLASKA AZURE Lantai 12 No. 09', value: 'KLASKA AZURE Lantai 12 No. 09'},
    {label: 'KLASKA AZURE Lantai 15 No. 17', value: 'KLASKA AZURE Lantai 15 No. 17'},
    {label: 'KLASKA AZURE Lantai 15 No. 31', value: 'KLASKA AZURE Lantai 15 No. 31'},
    {label: 'KLASKA AZURE Lantai 16 No. 12', value: 'KLASKA AZURE Lantai 16 No. 12'},
    {label: 'KLASKA AZURE Lantai 16 No. 15', value: 'KLASKA AZURE Lantai 16 No. 15'},
    {label: 'KLASKA AZURE Lantai 16 No. 30', value: 'KLASKA AZURE Lantai 16 No. 30'},
    {label: 'KLASKA AZURE Lantai 16 No. 31', value: 'KLASKA AZURE Lantai 16 No. 31'},
    {label: 'KLASKA AZURE Lantai 18 No. 01', value: 'KLASKA AZURE Lantai 18 No. 01'},
    {label: 'KLASKA AZURE Lantai 19 No. 12', value: 'KLASKA AZURE Lantai 19 No. 12'},
    {label: 'KLASKA AZURE Lantai 19 No. 18', value: 'KLASKA AZURE Lantai 19 No. 18'},
    {label: 'KLASKA AZURE Lantai 19 No. 19', value: 'KLASKA AZURE Lantai 19 No. 19'},
    {label: 'KLASKA AZURE Lantai 19 No. 31', value: 'KLASKA AZURE Lantai 19 No. 31'},
    {label: 'KLASKA AZURE Lantai 19 No. 33', value: 'KLASKA AZURE Lantai 19 No. 33'},
    {label: 'KLASKA AZURE Lantai 20 No. 09', value: 'KLASKA AZURE Lantai 20 No. 09'},
    {label: 'KLASKA AZURE Lantai 20 No. 12', value: 'KLASKA AZURE Lantai 20 No. 12'},
    {label: 'KLASKA AZURE Lantai 20 No. 17', value: 'KLASKA AZURE Lantai 20 No. 17'},
    {label: 'KLASKA AZURE Lantai 21 No. 01', value: 'KLASKA AZURE Lantai 21 No. 01'},
    {label: 'KLASKA AZURE Lantai 21 No. 33', value: 'KLASKA AZURE Lantai 21 No. 33'},
    {label: 'KLASKA AZURE Lantai 22 No. 21', value: 'KLASKA AZURE Lantai 22 No. 21'},
    {label: 'KLASKA AZURE Lantai 22 No. 26', value: 'KLASKA AZURE Lantai 22 No. 26'},
    {label: 'KLASKA AZURE Lantai 22 No. 30', value: 'KLASKA AZURE Lantai 22 No. 30'},
    {label: 'KLASKA AZURE Lantai 23 No. 01', value: 'KLASKA AZURE Lantai 23 No. 01'},
    {label: 'KLASKA AZURE Lantai 23 No. 11', value: 'KLASKA AZURE Lantai 23 No. 11'},
    {label: 'KLASKA AZURE Lantai 23 No. 12', value: 'KLASKA AZURE Lantai 23 No. 12'},
    {label: 'KLASKA AZURE Lantai 23 No. 30', value: 'KLASKA AZURE Lantai 23 No. 30'},
    {label: 'KLASKA AZURE Lantai 23 No. 31', value: 'KLASKA AZURE Lantai 23 No. 31'},
    {label: 'KLASKA AZURE Lantai 23 No. 33', value: 'KLASKA AZURE Lantai 23 No. 33'},
    {label: 'KLASKA AZURE Lantai 25 No. 09', value: 'KLASKA AZURE Lantai 25 No. 09'},
    {label: 'KLASKA AZURE Lantai 25 No. 30', value: 'KLASKA AZURE Lantai 25 No. 30'},
    {label: 'KLASKA AZURE Lantai 25 No. 31', value: 'KLASKA AZURE Lantai 25 No. 31'},
    {label: 'KLASKA AZURE Lantai 26 No. 01', value: 'KLASKA AZURE Lantai 26 No. 01'},
    {label: 'KLASKA AZURE Lantai 26 No. 05', value: 'KLASKA AZURE Lantai 26 No. 05'},
    {label: 'KLASKA AZURE Lantai 26 No. 12', value: 'KLASKA AZURE Lantai 26 No. 12'},
    {label: 'KLASKA AZURE Lantai 26 No. 18', value: 'KLASKA AZURE Lantai 26 No. 18'},
    {label: 'KLASKA AZURE Lantai 26 No. 20', value: 'KLASKA AZURE Lantai 26 No. 20'},
    {label: 'KLASKA AZURE Lantai 26 No. 21', value: 'KLASKA AZURE Lantai 26 No. 21'},
    {label: 'KLASKA AZURE Lantai 26 No. 25', value: 'KLASKA AZURE Lantai 26 No. 25'},
    {label: 'KLASKA AZURE Lantai 26 No. 30', value: 'KLASKA AZURE Lantai 26 No. 30'},
    {label: 'KLASKA AZURE Lantai 26 No. 31', value: 'KLASKA AZURE Lantai 26 No. 31'},
    {label: 'KLASKA AZURE Lantai 26 No. 32', value: 'KLASKA AZURE Lantai 26 No. 32'},
    {label: 'KLASKA AZURE Lantai 26 No. 33', value: 'KLASKA AZURE Lantai 26 No. 33'},
    {label: 'KLASKA AZURE Lantai 27 No. 01', value: 'KLASKA AZURE Lantai 27 No. 01'},
    {label: 'KLASKA AZURE Lantai 27 No. 12', value: 'KLASKA AZURE Lantai 27 No. 12'},
    {label: 'KLASKA AZURE Lantai 27 No. 16', value: 'KLASKA AZURE Lantai 27 No. 16'},
    {label: 'KLASKA AZURE Lantai 27 No. 19', value: 'KLASKA AZURE Lantai 27 No. 19'},
    {label: 'KLASKA AZURE Lantai 27 No. 20', value: 'KLASKA AZURE Lantai 27 No. 20'},
    {label: 'KLASKA AZURE Lantai 27 No. 21', value: 'KLASKA AZURE Lantai 27 No. 21'},
    {label: 'KLASKA AZURE Lantai 27 No. 32', value: 'KLASKA AZURE Lantai 27 No. 32'},
    {label: 'KLASKA AZURE Lantai 27 No. 33', value: 'KLASKA AZURE Lantai 27 No. 33'},
    {label: 'KLASKA AZURE Lantai 27 No. 37', value: 'KLASKA AZURE Lantai 27 No. 37'},
    {label: 'KLASKA AZURE Lantai 28 No. 12', value: 'KLASKA AZURE Lantai 28 No. 12'},
    {label: 'KLASKA AZURE Lantai 28 No. 20', value: 'KLASKA AZURE Lantai 28 No. 20'},
    {label: 'KLASKA AZURE Lantai 28 No. 25', value: 'KLASKA AZURE Lantai 28 No. 25'},
    {label: 'KLASKA AZURE Lantai 28 No. 33', value: 'KLASKA AZURE Lantai 28 No. 33'},
    {label: 'KLASKA AZURE Lantai 29 No. 03', value: 'KLASKA AZURE Lantai 29 No. 03'},
    {label: 'KLASKA AZURE Lantai 29 No. 20', value: 'KLASKA AZURE Lantai 29 No. 20'},
    {label: 'KLASKA AZURE Lantai 30 No. 01', value: 'KLASKA AZURE Lantai 30 No. 01'},
    {label: 'KLASKA AZURE Lantai 30 No. 03', value: 'KLASKA AZURE Lantai 30 No. 03'},
    {label: 'KLASKA AZURE Lantai 30 No. 05', value: 'KLASKA AZURE Lantai 30 No. 05'},
    {label: 'KLASKA AZURE Lantai 30 No. 18', value: 'KLASKA AZURE Lantai 30 No. 18'},
    {label: 'KLASKA AZURE Lantai 30 No. 20', value: 'KLASKA AZURE Lantai 30 No. 20'},
    {label: 'KLASKA AZURE Lantai 31 No. 18', value: 'KLASKA AZURE Lantai 31 No. 18'},
    {label: 'KLASKA AZURE Lantai 31 No. 20', value: 'KLASKA AZURE Lantai 31 No. 20'},
    {label: 'KLASKA AZURE Lantai 31 No. 21', value: 'KLASKA AZURE Lantai 31 No. 21'},
    {label: 'KLASKA AZURE Lantai 31 No. 22', value: 'KLASKA AZURE Lantai 31 No. 22'},
    {label: 'KLASKA AZURE Lantai 31 No. 28', value: 'KLASKA AZURE Lantai 31 No. 28'},
    {label: 'KLASKA AZURE Lantai 31 No. 31', value: 'KLASKA AZURE Lantai 31 No. 31'},
    {label: 'KLASKA AZURE Lantai 31 No. 33', value: 'KLASKA AZURE Lantai 31 No. 33'},
    {label: 'KLASKA AZURE Lantai 31 No. 35', value: 'KLASKA AZURE Lantai 31 No. 35'},
    {label: 'KLASKA AZURE Lantai 32 No. 05', value: 'KLASKA AZURE Lantai 32 No. 05'},
    {label: 'KLASKA AZURE Lantai 32 No. 20', value: 'KLASKA AZURE Lantai 32 No. 20'},
    {label: 'KLASKA AZURE Lantai 32 No. 21', value: 'KLASKA AZURE Lantai 32 No. 21'},
    {label: 'KLASKA AZURE Lantai 32 No. 22', value: 'KLASKA AZURE Lantai 32 No. 22'},
    {label: 'KLASKA AZURE Lantai 32 No. 25', value: 'KLASKA AZURE Lantai 32 No. 25'},
    {label: 'KLASKA AZURE Lantai 32 No. 26', value: 'KLASKA AZURE Lantai 32 No. 26'},
    {label: 'KLASKA AZURE Lantai 32 No. 31', value: 'KLASKA AZURE Lantai 32 No. 31'},
    {label: 'KLASKA AZURE Lantai 50 No. 01', value: 'KLASKA AZURE Lantai 50 No. 01'},
    {label: 'KLASKA AZURE Lantai 50 No. 03', value: 'KLASKA AZURE Lantai 50 No. 03'},
    {label: 'KLASKA AZURE Lantai 50 No. 05', value: 'KLASKA AZURE Lantai 50 No. 05'},
    {label: 'KLASKA AZURE Lantai 50 No. 18', value: 'KLASKA AZURE Lantai 50 No. 18'},
    {label: 'KLASKA AZURE Lantai 50 No. 20', value: 'KLASKA AZURE Lantai 50 No. 20'},
    {label: 'KLASKA AZURE Lantai 50 No. 21', value: 'KLASKA AZURE Lantai 50 No. 21'},
    {label: 'KLASKA AZURE Lantai 50 No. 22', value: 'KLASKA AZURE Lantai 50 No. 22'},
    {label: 'KLASKA AZURE Lantai 50 No. 28', value: 'KLASKA AZURE Lantai 50 No. 28'},
    {label: 'KLASKA AZURE Lantai 50 No. 29', value: 'KLASKA AZURE Lantai 50 No. 29'},
    {label: 'KLASKA AZURE Lantai 50 No. 30', value: 'KLASKA AZURE Lantai 50 No. 30'},
    {label: 'KLASKA AZURE Lantai 50 No. 31', value: 'KLASKA AZURE Lantai 50 No. 31'},
    {label: 'KLASKA AZURE Lantai 50 No. 32', value: 'KLASKA AZURE Lantai 50 No. 32'},
    {label: 'KLASKA AZURE Lantai 50 No. 33', value: 'KLASKA AZURE Lantai 50 No. 33'},
    {label: 'KLASKA AZURE Lantai 50 No. 35', value: 'KLASKA AZURE Lantai 50 No. 35'},
    {label: 'KLASKA AZURE Lantai 50 No. 36', value: 'KLASKA AZURE Lantai 50 No. 36'},
    {label: 'KLASKA AZURE Lantai 50 No. 38', value: 'KLASKA AZURE Lantai 50 No. 38'},
    {label: 'KLASKA AZURE Lantai 51 No. 01', value: 'KLASKA AZURE Lantai 51 No. 01'},
    {label: 'KLASKA AZURE Lantai 51 No. 03', value: 'KLASKA AZURE Lantai 51 No. 03'},
    {label: 'KLASKA AZURE Lantai 51 No. 05', value: 'KLASKA AZURE Lantai 51 No. 05'},
    {label: 'KLASKA AZURE Lantai 51 No. 08', value: 'KLASKA AZURE Lantai 51 No. 08'},
    {label: 'KLASKA AZURE Lantai 51 No. 18', value: 'KLASKA AZURE Lantai 51 No. 18'},
    {label: 'KLASKA AZURE Lantai 51 No. 20', value: 'KLASKA AZURE Lantai 51 No. 20'},
    {label: 'KLASKA AZURE Lantai 51 No. 21', value: 'KLASKA AZURE Lantai 51 No. 21'},
    {label: 'KLASKA AZURE Lantai 51 No. 22', value: 'KLASKA AZURE Lantai 51 No. 22'},
    {label: 'KLASKA AZURE Lantai 51 No. 25', value: 'KLASKA AZURE Lantai 51 No. 25'},
    {label: 'KLASKA AZURE Lantai 51 No. 26', value: 'KLASKA AZURE Lantai 51 No. 26'},
    {label: 'KLASKA AZURE Lantai 51 No. 28', value: 'KLASKA AZURE Lantai 51 No. 28'},
    {label: 'KLASKA AZURE Lantai 51 No. 29', value: 'KLASKA AZURE Lantai 51 No. 29'},
    {label: 'KLASKA AZURE Lantai 51 No. 30', value: 'KLASKA AZURE Lantai 51 No. 30'},
    {label: 'KLASKA AZURE Lantai 51 No. 31', value: 'KLASKA AZURE Lantai 51 No. 31'},
    {label: 'KLASKA AZURE Lantai 51 No. 32', value: 'KLASKA AZURE Lantai 51 No. 32'},
    {label: 'KLASKA AZURE Lantai 51 No. 33', value: 'KLASKA AZURE Lantai 51 No. 33'},
    {label: 'KLASKA AZURE Lantai 51 No. 35', value: 'KLASKA AZURE Lantai 51 No. 35'},
    {label: 'KLASKA AZURE Lantai 51 No. 38', value: 'KLASKA AZURE Lantai 51 No. 38'},
    {label: 'KLASKA AZURE Lantai 52 No. 01', value: 'KLASKA AZURE Lantai 52 No. 01'},
    {label: 'KLASKA AZURE Lantai 52 No. 03', value: 'KLASKA AZURE Lantai 52 No. 03'},
    {label: 'KLASKA AZURE Lantai 52 No. 05', value: 'KLASKA AZURE Lantai 52 No. 05'},
    {label: 'KLASKA AZURE Lantai 52 No. 08', value: 'KLASKA AZURE Lantai 52 No. 08'},
    {label: 'KLASKA AZURE Lantai 52 No. 18', value: 'KLASKA AZURE Lantai 52 No. 18'},
    {label: 'KLASKA AZURE Lantai 52 No. 20', value: 'KLASKA AZURE Lantai 52 No. 20'},
    {label: 'KLASKA AZURE Lantai 52 No. 21', value: 'KLASKA AZURE Lantai 52 No. 21'},
    {label: 'KLASKA AZURE Lantai 52 No. 22', value: 'KLASKA AZURE Lantai 52 No. 22'},
    {label: 'KLASKA AZURE Lantai 52 No. 25', value: 'KLASKA AZURE Lantai 52 No. 25'},
    {label: 'KLASKA AZURE Lantai 52 No. 26', value: 'KLASKA AZURE Lantai 52 No. 26'},
    {label: 'KLASKA AZURE Lantai 52 No. 29', value: 'KLASKA AZURE Lantai 52 No. 29'},
    {label: 'KLASKA AZURE Lantai 52 No. 30', value: 'KLASKA AZURE Lantai 52 No. 30'},
    {label: 'KLASKA AZURE Lantai 52 No. 31', value: 'KLASKA AZURE Lantai 52 No. 31'},
    {label: 'KLASKA AZURE Lantai 52 No. 32', value: 'KLASKA AZURE Lantai 52 No. 32'},
    {label: 'KLASKA AZURE Lantai 52 No. 33', value: 'KLASKA AZURE Lantai 52 No. 33'},
    {label: 'KLASKA AZURE Lantai 52 No. 35', value: 'KLASKA AZURE Lantai 52 No. 35'},
    {label: 'KLASKA AZURE Lantai 52 No. 36', value: 'KLASKA AZURE Lantai 52 No. 36'},
    {label: 'KLASKA AZURE Lantai 52 No. 38', value: 'KLASKA AZURE Lantai 52 No. 38'},
    {label: 'KLASKA AZURE Lantai 53 No. 01', value: 'KLASKA AZURE Lantai 53 No. 01'},
    {label: 'KLASKA AZURE Lantai 53 No. 02', value: 'KLASKA AZURE Lantai 53 No. 02'},
    {label: 'KLASKA AZURE Lantai 53 No. 05', value: 'KLASKA AZURE Lantai 53 No. 05'},
    {label: 'KLASKA AZURE Lantai 53 No. 08', value: 'KLASKA AZURE Lantai 53 No. 08'},
    {label: 'KLASKA AZURE Lantai 53 No. 18', value: 'KLASKA AZURE Lantai 53 No. 18'},
    {label: 'KLASKA AZURE Lantai 53 No. 21', value: 'KLASKA AZURE Lantai 53 No. 21'},
    {label: 'KLASKA AZURE Lantai 53 No. 22', value: 'KLASKA AZURE Lantai 53 No. 22'},
    {label: 'KLASKA AZURE Lantai 53 No. 25', value: 'KLASKA AZURE Lantai 53 No. 25'},
    {label: 'KLASKA AZURE Lantai 53 No. 26', value: 'KLASKA AZURE Lantai 53 No. 26'},
    {label: 'KLASKA AZURE Lantai 53 No. 27', value: 'KLASKA AZURE Lantai 53 No. 27'},
    {label: 'KLASKA AZURE Lantai 53 No. 28', value: 'KLASKA AZURE Lantai 53 No. 28'},
    {label: 'KLASKA AZURE Lantai 53 No. 29', value: 'KLASKA AZURE Lantai 53 No. 29'},
    {label: 'KLASKA AZURE Lantai 53 No. 30', value: 'KLASKA AZURE Lantai 53 No. 30'},
    {label: 'KLASKA AZURE Lantai 53 No. 31', value: 'KLASKA AZURE Lantai 53 No. 31'},
    {label: 'KLASKA AZURE Lantai 53 No. 32', value: 'KLASKA AZURE Lantai 53 No. 32'},
    {label: 'KLASKA AZURE Lantai 53 No. 33', value: 'KLASKA AZURE Lantai 53 No. 33'},
    {label: 'KLASKA AZURE Lantai 53 No. 35', value: 'KLASKA AZURE Lantai 53 No. 35'},
    {label: 'KLASKA AZURE Lantai 53 No. 36', value: 'KLASKA AZURE Lantai 53 No. 36'},
    {label: 'KLASKA AZURE Lantai 55 No. 01', value: 'KLASKA AZURE Lantai 55 No. 01'},
    {label: 'KLASKA AZURE Lantai 55 No. 03', value: 'KLASKA AZURE Lantai 55 No. 03'},
    {label: 'KLASKA AZURE Lantai 55 No. 05', value: 'KLASKA AZURE Lantai 55 No. 05'},
    {label: 'KLASKA AZURE Lantai 55 No. 07', value: 'KLASKA AZURE Lantai 55 No. 07'},
    {label: 'KLASKA AZURE Lantai 55 No. 08', value: 'KLASKA AZURE Lantai 55 No. 08'},
    {label: 'KLASKA AZURE Lantai 55 No. 18', value: 'KLASKA AZURE Lantai 55 No. 18'},
    {label: 'KLASKA AZURE Lantai 55 No. 20', value: 'KLASKA AZURE Lantai 55 No. 20'},
    {label: 'KLASKA AZURE Lantai 55 No. 21', value: 'KLASKA AZURE Lantai 55 No. 21'},
    {label: 'KLASKA AZURE Lantai 55 No. 22', value: 'KLASKA AZURE Lantai 55 No. 22'},
    {label: 'KLASKA AZURE Lantai 55 No. 25', value: 'KLASKA AZURE Lantai 55 No. 25'},
    {label: 'KLASKA AZURE Lantai 55 No. 26', value: 'KLASKA AZURE Lantai 55 No. 26'},
    {label: 'KLASKA AZURE Lantai 55 No. 28', value: 'KLASKA AZURE Lantai 55 No. 28'},
    {label: 'KLASKA AZURE Lantai 55 No. 29', value: 'KLASKA AZURE Lantai 55 No. 29'},
    {label: 'KLASKA AZURE Lantai 55 No. 30', value: 'KLASKA AZURE Lantai 55 No. 30'},
    {label: 'KLASKA AZURE Lantai 55 No. 31', value: 'KLASKA AZURE Lantai 55 No. 31'},
    {label: 'KLASKA AZURE Lantai 55 No. 32', value: 'KLASKA AZURE Lantai 55 No. 32'},
    {label: 'KLASKA AZURE Lantai 55 No. 33', value: 'KLASKA AZURE Lantai 55 No. 33'},
    {label: 'KLASKA AZURE Lantai 55 No. 35', value: 'KLASKA AZURE Lantai 55 No. 35'},
    {label: 'KLASKA AZURE Lantai 55 No. 36', value: 'KLASKA AZURE Lantai 55 No. 36'},
    {label: 'KLASKA AZURE Lantai 55 No. 38', value: 'KLASKA AZURE Lantai 55 No. 38'},
    {label: 'KLASKA AZURE Lantai 56 No. 08', value: 'KLASKA AZURE Lantai 56 No. 08'},
    {label: 'KLASKA AZURE Lantai 56 No. 18', value: 'KLASKA AZURE Lantai 56 No. 18'},
    {label: 'KLASKA AZURE Lantai 56 No. 20', value: 'KLASKA AZURE Lantai 56 No. 20'},
    {label: 'KLASKA AZURE Lantai 56 No. 21', value: 'KLASKA AZURE Lantai 56 No. 21'},
    {label: 'KLASKA AZURE Lantai 56 No. 22', value: 'KLASKA AZURE Lantai 56 No. 22'},
    {label: 'KLASKA AZURE Lantai 56 No. 25', value: 'KLASKA AZURE Lantai 56 No. 25'},
    {label: 'KLASKA AZURE Lantai 56 No. 26', value: 'KLASKA AZURE Lantai 56 No. 26'},
    {label: 'KLASKA AZURE Lantai 56 No. 28', value: 'KLASKA AZURE Lantai 56 No. 28'},
    {label: 'KLASKA AZURE Lantai 56 No. 29', value: 'KLASKA AZURE Lantai 56 No. 29'},
    {label: 'KLASKA AZURE Lantai 56 No. 30', value: 'KLASKA AZURE Lantai 56 No. 30'},
    {label: 'KLASKA AZURE Lantai 56 No. 31', value: 'KLASKA AZURE Lantai 56 No. 31'},
    {label: 'KLASKA AZURE Lantai 56 No. 32', value: 'KLASKA AZURE Lantai 56 No. 32'},
    {label: 'KLASKA AZURE Lantai 56 No. 33', value: 'KLASKA AZURE Lantai 56 No. 33'},
    {label: 'KLASKA AZURE Lantai 56 No. 35', value: 'KLASKA AZURE Lantai 56 No. 35'},
    {label: 'KLASKA AZURE Lantai 56 No. 36', value: 'KLASKA AZURE Lantai 56 No. 36'},
    {label: 'KLASKA AZURE Lantai 56 No. 37', value: 'KLASKA AZURE Lantai 56 No. 37'},
    {label: 'KLASKA AZURE Lantai 56 No. 38', value: 'KLASKA AZURE Lantai 56 No. 38'},
    {label: 'KLASKA AZURE Lantai 7 No. 32', value: 'KLASKA AZURE Lantai 7 No. 32'},
    {label: 'KLASKA AZURE Lantai 7 No. 33', value: 'KLASKA AZURE Lantai 7 No. 33'},
    {label: 'KLASKA AZURE Lantai 7 No. 35', value: 'KLASKA AZURE Lantai 7 No. 35'},
    {label: 'KLASKA AZURE Lantai 8 No. 01', value: 'KLASKA AZURE Lantai 8 No. 01'},
    {label: 'KLASKA AZURE Lantai 8 No. 21', value: 'KLASKA AZURE Lantai 8 No. 21'},
    {label: 'KLASKA AZURE Lantai 9 No. 01', value: 'KLASKA AZURE Lantai 9 No. 01'},
    {label: 'KLASKA AZURE Lantai 10 No. 10', value: 'KLASKA AZURE Lantai 10 No. 10'},
    {label: 'KLASKA AZURE Lantai 11 No. 28', value: 'KLASKA AZURE Lantai 11 No. 28'},
    {label: 'KLASKA AZURE Lantai 16 No. 36', value: 'KLASKA AZURE Lantai 16 No. 36'},
    {label: 'KLASKA AZURE Lantai 18 No. 38', value: 'KLASKA AZURE Lantai 18 No. 38'},
    {label: 'KLASKA AZURE Lantai 15 No. 28', value: 'KLASKA AZURE Lantai 15 No. 28'},
    {label: 'KLASKA AZURE Lantai 28 No. 38', value: 'KLASKA AZURE Lantai 28 No. 38'},
  ]

  let kainaUnitList = [
    {label: 'The Nove Tower1 Lt 02 No A10', value: 'The Nove Tower1 Lt 02 No A10'},
    {label: 'The Nove Tower1 Lt 02 No A11', value: 'The Nove Tower1 Lt 02 No A11'},
    {label: 'The Nove Tower1 Lt 02 No A15', value: 'The Nove Tower1 Lt 02 No A15'},
    {label: 'The Nove Tower1 Lt 02 No B25', value: 'The Nove Tower1 Lt 02 No B25'},
    {label: 'The Nove Tower1 Lt 03 No A12', value: 'The Nove Tower1 Lt 03 No A12'},
    {label: 'The Nove Tower1 Lt 03 No A15', value: 'The Nove Tower1 Lt 03 No A15'},
    {label: 'The Nove Tower1 Lt 03 No B22', value: 'The Nove Tower1 Lt 03 No B22'},
    {label: 'The Nove Tower1 Lt 03 No B23', value: 'The Nove Tower1 Lt 03 No B23'},
    {label: 'The Nove Tower1 Lt 03 No B26', value: 'The Nove Tower1 Lt 03 No B26'},
    {label: 'The Nove Tower1 Lt 03 No B27', value: 'The Nove Tower1 Lt 03 No B27'},
    {label: 'The Nove Tower1 Lt 05 No A10', value: 'The Nove Tower1 Lt 05 No A10'},
    {label: 'The Nove Tower1 Lt 05 No A12', value: 'The Nove Tower1 Lt 05 No A12'},
    {label: 'The Nove Tower1 Lt 06 No A11', value: 'The Nove Tower1 Lt 06 No A11'},
    {label: 'The Nove Tower1 Lt 06 No B21', value: 'The Nove Tower1 Lt 06 No B21'},
    {label: 'The Nove Tower1 Lt 07 No A11', value: 'The Nove Tower1 Lt 07 No A11'},
    {label: 'The Nove Tower1 Lt 07 No A12', value: 'The Nove Tower1 Lt 07 No A12'},
    {label: 'The Nove Tower1 Lt 07 No A15', value: 'The Nove Tower1 Lt 07 No A15'},
    {label: 'The Nove Tower1 Lt 09 No A10', value: 'The Nove Tower1 Lt 09 No A10'},
    {label: 'The Nove Tower1 Lt GF No A07', value: 'The Nove Tower1 Lt GF No A07'},
    {label: 'The Nove Tower1 Lt GF No B12', value: 'The Nove Tower1 Lt GF No B12'},
  ]

  let kalaniUnitList = [
    {label: 'The Nove Tower Kalani Lt 10 No D11', value: 'The Nove Tower Kalani Lt 10 No D11'},
    {label: 'The Nove Tower Kalani Lt 10 No D12', value: 'The Nove Tower Kalani Lt 10 No D12'},
    {label: 'The Nove Tower Kalani Lt 11 No D11', value: 'The Nove Tower Kalani Lt 11 No D11'},
    {label: 'The Nove Tower Kalani Lt 11 No D12', value: 'The Nove Tower Kalani Lt 11 No D12'},
    {label: 'The Nove Tower Kalani Lt 12 No D12', value: 'The Nove Tower Kalani Lt 12 No D12'},
    {label: 'The Nove Tower Kalani Lt 17 No C01', value: 'The Nove Tower Kalani Lt 17 No C01'},
    {label: 'The Nove Tower Kalani Lt 17 No C05', value: 'The Nove Tower Kalani Lt 17 No C05'},
    {label: 'The Nove Tower Kalani Lt 17 No D06', value: 'The Nove Tower Kalani Lt 17 No D06'},
    {label: 'The Nove Tower Kalani Lt 18 No C05', value: 'The Nove Tower Kalani Lt 18 No C05'},
    {label: 'The Nove Tower Kalani Lt 18 No D06', value: 'The Nove Tower Kalani Lt 18 No D06'},
    {label: 'The Nove Tower Kalani Lt 18 No D07', value: 'The Nove Tower Kalani Lt 18 No D07'},
    {label: 'The Nove Tower Kalani Lt 18 No D11', value: 'The Nove Tower Kalani Lt 18 No D11'},
    {label: 'The Nove Tower Kalani Lt 19 No C05', value: 'The Nove Tower Kalani Lt 19 No C05'},
    {label: 'The Nove Tower Kalani Lt 19 No D06', value: 'The Nove Tower Kalani Lt 19 No D06'},
    {label: 'The Nove Tower Kalani Lt 19 No D07', value: 'The Nove Tower Kalani Lt 19 No D07'},
    {label: 'The Nove Tower Kalani Lt 19 No D11', value: 'The Nove Tower Kalani Lt 19 No D11'},
    {label: 'The Nove Tower Kalani Lt 2 No C01', value: 'The Nove Tower Kalani Lt 2 No C01'},
    {label: 'The Nove Tower Kalani Lt 2 No C02', value: 'The Nove Tower Kalani Lt 2 No C02'},
    {label: 'The Nove Tower Kalani Lt 2 No C07', value: 'The Nove Tower Kalani Lt 2 No C07'},
    {label: 'The Nove Tower Kalani Lt 2 No C08', value: 'The Nove Tower Kalani Lt 2 No C08'},
    {label: 'The Nove Tower Kalani Lt 2 No D11', value: 'The Nove Tower Kalani Lt 2 No D11'},
    {label: 'The Nove Tower Kalani Lt 2 No D12', value: 'The Nove Tower Kalani Lt 2 No D12'},
    {label: 'The Nove Tower Kalani Lt 2 No D18', value: 'The Nove Tower Kalani Lt 2 No D18'},
    {label: 'The Nove Tower Kalani Lt 2 No D19', value: 'The Nove Tower Kalani Lt 2 No D19'},
    {label: 'The Nove Tower Kalani Lt 3 No C01', value: 'The Nove Tower Kalani Lt 3 No C01'},
    {label: 'The Nove Tower Kalani Lt 3 No C02', value: 'The Nove Tower Kalani Lt 3 No C02'},
    {label: 'The Nove Tower Kalani Lt 3 No C08', value: 'The Nove Tower Kalani Lt 3 No C08'},
    {label: 'The Nove Tower Kalani Lt 3 No D11', value: 'The Nove Tower Kalani Lt 3 No D11'},
    {label: 'The Nove Tower Kalani Lt 3 No D12', value: 'The Nove Tower Kalani Lt 3 No D12'},
    {label: 'The Nove Tower Kalani Lt 3 No D18', value: 'The Nove Tower Kalani Lt 3 No D18'},
    {label: 'The Nove Tower Kalani Lt 3 No D19', value: 'The Nove Tower Kalani Lt 3 No D19'},
    {label: 'The Nove Tower Kalani Lt 5 No C01', value: 'The Nove Tower Kalani Lt 5 No C01'},
    {label: 'The Nove Tower Kalani Lt 5 No C08', value: 'The Nove Tower Kalani Lt 5 No C08'},
    {label: 'The Nove Tower Kalani Lt 5 No D11', value: 'The Nove Tower Kalani Lt 5 No D11'},
    {label: 'The Nove Tower Kalani Lt 5 No D12', value: 'The Nove Tower Kalani Lt 5 No D12'},
    {label: 'The Nove Tower Kalani Lt 5 No D19', value: 'The Nove Tower Kalani Lt 5 No D19'},
    {label: 'The Nove Tower Kalani Lt 6 No C01', value: 'The Nove Tower Kalani Lt 6 No C01'},
    {label: 'The Nove Tower Kalani Lt 6 No C08', value: 'The Nove Tower Kalani Lt 6 No C08'},
    {label: 'The Nove Tower Kalani Lt 6 No D11', value: 'The Nove Tower Kalani Lt 6 No D11'},
    {label: 'The Nove Tower Kalani Lt 6 No D12', value: 'The Nove Tower Kalani Lt 6 No D12'},
    {label: 'The Nove Tower Kalani Lt 6 No D19', value: 'The Nove Tower Kalani Lt 6 No D19'},
    {label: 'The Nove Tower Kalani Lt 7 No C01', value: 'The Nove Tower Kalani Lt 7 No C01'},
    {label: 'The Nove Tower Kalani Lt 7 No C08', value: 'The Nove Tower Kalani Lt 7 No C08'},
    {label: 'The Nove Tower Kalani Lt 7 No D11', value: 'The Nove Tower Kalani Lt 7 No D11'},
    {label: 'The Nove Tower Kalani Lt 7 No D12', value: 'The Nove Tower Kalani Lt 7 No D12'},
    {label: 'The Nove Tower Kalani Lt 7 No D19', value: 'The Nove Tower Kalani Lt 7 No D19'},
    {label: 'The Nove Tower Kalani Lt 8 No C08', value: 'The Nove Tower Kalani Lt 8 No C08'},
    {label: 'The Nove Tower Kalani Lt 8 No D12', value: 'The Nove Tower Kalani Lt 8 No D12'},
    {label: 'The Nove Tower Kalani Lt 8 No D19', value: 'The Nove Tower Kalani Lt 8 No D19'},
    {label: 'The Nove Tower Kalani Lt 9 No C08', value: 'The Nove Tower Kalani Lt 9 No C08'},
    {label: 'The Nove Tower Kalani Lt 9 No D11', value: 'The Nove Tower Kalani Lt 9 No D11'},
    {label: 'The Nove Tower Kalani Lt 9 No D12', value: 'The Nove Tower Kalani Lt 9 No D12'},
    {label: 'The Nove Tower Kalani Lt GF No C01', value: 'The Nove Tower Kalani Lt GF No C01'},
    {label: 'The Nove Tower Kalani Lt GF No C02', value: 'The Nove Tower Kalani Lt GF No C02'},
    {label: 'The Nove Tower Kalani Lt GF No C05', value: 'The Nove Tower Kalani Lt GF No C05'},
    {label: 'The Nove Tower Kalani Lt GF No C06', value: 'The Nove Tower Kalani Lt GF No C06'},
    {label: 'The Nove Tower Kalani Lt GF No C07', value: 'The Nove Tower Kalani Lt GF No C07'},    
  ]

  const changeUnitSelector = (product) => {
    let triggerValue = ""
    if (product !== undefined) {
      switch (product) {
        case "Aerium, Jakarta":
          triggerValue = aeriumUnitList
          break;
        case "Azure, Klaska Residence, Surabaya":
          triggerValue = klaskaUnitList
          break;
        case "Element, Jakarta":
          triggerValue = elementsUnitList
          break;
        case "Southgate Altuerra, Jakarta":
          triggerValue = altueraUnitList
          break;
        case "Southgate Elegance, Jakarta":
          triggerValue = eleganceUnitList
          break;
        case "Tower Kaina, Batam":
          triggerValue = kainaUnitList
          break;
        case "Tower Kalani, Batam":
          triggerValue = kalaniUnitList
          break;
      }
    }else{
      triggerValue = [{
        label: "Unit Yang Dibeli",
        value: "",
      }]
    }
    return triggerValue
  }

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {

    const phoneNumberHandler = (val, setFieldValue) =>setFieldValue("phoneNumber", val)

    const [checked, setChecked] = useState(false)
  
    function handleOnChange(){
      setChecked(!checked)
    }

    return (
      <Form className="row pt-4" id="spin-wheel-apartemen-form">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={(val) =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Produk Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Produk Yang Dibeli"
            options={listOfProduct}
            defaultValue={{ value: "", label: "Produk Yang Dibeli" }}
            id="product"
            onChange={(e) => {
              productSelected.current = e.value
              setFieldValue("product", e.value)
              sessionStorage.setItem("product", e.value)
            }}
          />
          {touched.product && errors.product && (
            <div className="invalid-value d-block">{errors.product}</div>
          )}
        </div>    
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Unit Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Unit Yang Dibeli"
            options={changeUnitSelector(productSelected.current)}
            defaultValue={{ value: "", label: "Unit Yang Dibeli" }}
            id="unit"
            onChange={(e) => {
              setFieldValue("unit", e.value)
              sessionStorage.setItem("unit", e.value)
            }}
          />
          {touched.unit && errors.unit && (
            <div className="invalid-value d-block">{errors.unit}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Sales</label>
          <Field
            name="salesName"
            className="form-control px-0"
            placeholder="Nama sales"
            type="text"
            id="salesName"
          />
          {touched.salesName && errors.salesName && (
            <div className="invalid-value d-block">{errors.salesName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="checked" 
              value="Agent" 
              onClick={handleOnChange}
            />
            &nbsp;Agent
          </label>
        </div>
        <div className={checked ? "col-12 col-md-12 pb-3 d-block" : "col-12 col-md-12 pb-3 d-none"}>
          <label className="text-muted my-0">Nama Kantor</label>
          <Field
            name="officeName"
            className="form-control px-0"
            placeholder="Nama Kantor"
            type="text"
            id="officeName"
          />
          {touched.officeName && errors.officeName && (
            <div className="invalid-value d-block">{errors.officeName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="isAgree" 
              value="isAgree" 
              id="isAgree"
            />
            &nbsp;Saya menyetujui bahwa keputusan Spin to Wheel Apartemen 2023 ini tidak dapat diganggu gugat.
          </label>
          {touched.isAgree && errors.isAgree && (
            <div className="invalid-value d-block">{errors.isAgree}</div>
          )}
        </div>
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }
  
  useEffect(() => {
    if (location.uri == "/apartemen-add") {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove('d-flex')
      document.querySelector("#lang-wrapper").classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: "",
    unit: "",
    salesName: "",
    officeName: "",
    isAgree: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, unit, salesName, officeName, isAgree}) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        unit: formValues.unit || unit,
        salesName: formValues.salesName || salesName,
        officeName: formValues.officeName || officeName,
        isAgree: formValues.isAgree || isAgree,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      unit: Yup.string().required("Unit Yang Dibeli is required"),
      salesName: Yup.string().required("Nama sales is required"),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formSpinWheelApartemen
      // let counter = 0

      for (let i = 0; i < dataList.length; i++) {

        if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("") && dataList[i].project.trim() == values.product.trim()) {
          console.log(">>1")
          if (dataList[i].unit.trim() == values.unit.trim()) {
            console.log(">>2")
            alert("Maaf! Untuk dapat mengikuti program Apartemen Spin To Wheel kembali, Anda harus memilih unit yang berbeda dengan sebelumnya.")
            return false
          }
        }

      }

        console.log(">>5")
        let form = document.getElementById("spin-wheel-apartemen-form")
        for (let q = 0; q < form.elements.length; q++) {
          let eSecond = form.elements[q]
          if (eSecond.type === "button") continue
          let idSecond = eSecond.id
          var valueSecond = eSecond.value
          sessionStorage.setItem(idSecond, valueSecond)
        }
        navigate("/apartemen-add/spin")

    },
  })(LeadForm)

  useEffect(() => {
    if (resizeScreen) {
      // document.querySelector("#stw-apartemen-section").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector("#stw-apartemen-section").style.backgroundColor = `#fff`
      document.querySelector("#stw-apartemen-section").style.backgroundSize = `cover`
    } else {
      // document.querySelector("#stw-apartemen-section").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector("#stw-apartemen-section").style.backgroundColor = `#fff`
      document.querySelector("#stw-apartemen-section").style.backgroundSize = `contain`
    }
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri} isApartemen={true}>
      <SEO 
        title={`Gimmick Apartemen 2023`}
        fbTitle={`Gimmick Apartemen 2023`}
        twitterTitle={`Gimmick Apartemen 2023`}
        description={`Dapatkan Additional discount apartemen dengan sistem spin wheel dengan range 0.5% sampai dengan 1.5%.`}
        fbDescription={`Dapatkan Additional discount apartemen dengan sistem spin wheel dengan range 0.5% sampai dengan 1.5%.`}
        twitterDescription={`Dapatkan Additional discount apartemen dengan sistem spin wheel dengan range 0.5% sampai dengan 1.5%.`}
        url={ogUrl}
        img={formBackgroundImg}
        fbImg={formBackgroundImg}
        twitterImg={formBackgroundImg}
      />
      <Helmet>
        <body className="bd-page" />
        <meta 
          name="keywords" 
          content="spin wheel apartemen 2023, diskon properti sinar mas land, program spin wheel apartemen."
        ></meta>
        <meta 
          name="copyright" 
          content="2023 | Sinar Mas Land"
        ></meta>
      </Helmet>
      <Section className="main-apartemen-stw bg-gray-50" id="stw-apartemen-section">
        <Container className="surpriseForm">
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default ApartemenPage
